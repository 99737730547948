export function formatDate(date: string): string {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  return d.toLocaleDateString("en", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function formatNum(): string {
  return "0";
}

import "./AboutPage.scss";
import OldVines from "../../images/aboutoldvines.jpg";
import styled from "styled-components";
import { useEffect } from 'react';
import OldVineConference from "../../images/old-vine-conference.jpg";
import { useNavigate } from "react-router-dom";
import JRDotCom from "../../images/jr-dotcom.jpg";
import JFWLogo from "../../images/JFWLogo2018.png";
import Vinography from "../../images/vinography_logo.png";
import { Image } from "react-bootstrap";
import { Paths } from "../../paths";

const PicturePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40rem;
  padding: 1rem;
  position: relative; /* Ensure the div positions its children correctly */
  color: white; /* Ensure text is readable over the background */
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${OldVines});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(80%);
  z-index: 0;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1; /* Ensure the text is above the background image */
`;

const SmallerSponsorImage = ({ src }: { src: string }) => (
  <div className="sponsor-image">
    <div className="sponsor-image-smaller">
      <Image src={src} fluid={true} />
    </div>
  </div>
);

export const AboutPage = () => {
  useEffect(() => {
        // Set the title when the component mounts
        document.title = 'About the Old Vine Registry';
        
        // Clean up the title when the component unmounts
        return () => {
          document.title = 'Old Vine Registry';
        };
      }, []); // Empty dependency array ensures this effect runs only once
const navigate = useNavigate();

  return (
    <div>
        <div>
            <PicturePanel>
                <BackgroundImage />
                <TextContainer className="alegreya-heading-white">
                    About the Old Vine Registry
                </TextContainer>
            </PicturePanel>
        </div>
      <div className="about-layout">
        <p className="description-text u-mt-large">
            The Old Vine Registry is a grassroots, volunteer effort inspired and begun by
            wine critic{" "}
            <a href="https://www.jancisrobinson.com/"
                rel="noreferrer"
                target="_blank"
            >
                Jancis Robinson
            </a>
            {" "}and several of her colleagues. Robinson first started
            writing about the heritage of old vines more than 15 years ago, and in 2010 she
            and writer{" "}
            <a href="https://www.jancisrobinson.com/team"
                rel="noreferrer"
                target="_blank"
            >
                Tamlyn Currin
            </a>
            {" "}began compiling a{" "}
            <a href="https://www.jancisrobinson.com/articles/our-unique-old-vines-register"
                rel="noreferrer"
                target="_blank"
            >
                registry of old vines
            </a>
            , triggered by a
            growing sense that these old vineyards were important elements of cultural and
            scientific heritage as well as the source of some particularly beautiful wine.
        </p>
        <p className="description-text">
            In 2013, Jancis wrote about{" "}
            <a href="https://www.jancisrobinson.com/articles/rosa-kruger-old-vine-champion"
                rel="noreferrer"
                target="_blank"
            >
                her encounter
            </a>
            {" "}with the extraordinary Rosa Kruger,
            who was ‘much preoccupied with a project to catalogue all the Cape&#39;s old vines’,
            work she had begun in 2002 and which has become South Africa’s{" "}
            <a href="https://oldvineproject.co.za/"
                rel="noreferrer"
                target="_blank"
            >
                Old Vine Project.
            </a>
        </p>
        <p className="description-text">
            Over the years, as Robinson and Currin came across them in one way or another,
            they gradually added more and more vineyards to their very simple spreadsheet,
            helped enormously in recent years by Benjamin Roelfs.
            The aim, at that time, was to create a record of these vineyards, and, if possible,
            make people aware of them and the beautiful wine that came from them –
            highlight the treasure they represented: liquid history, liquid pleasure.
        </p>
        <p className="description-text">
            Although the information gathered was rudimentary, over the years the
            spreadsheet grew into a rather unwieldy beast, much augmented by the 2021
            writing competition on Jancisrobinson.com, which was inspired, in part, by the
            inauguration of the{" "}
            <a href="https://www.oldvines.org/"
                rel="noreferrer"
                target="_blank"
            >
                Old Vine Conference
            </a>
            . This non-profit organization was co-founded
            by the indefatigable Sarah Abbott MW, who finally decided that there’d been enough
            talk about old vines, and, as Abbott put it, it was time ‘to galvanise a global
            movement’ to save old-vine vineyards by highlighting their value and thereby
            making them economically viable.
        </p>
        <p className="description-text">
            The registry has also been inspired by the work of organizations such as the {" "}
            <a href="https://barossawine.com/vineyards/old-vine-charter/"
                rel="noreferrer"
                target="_blank">
                Barossa Old Vine Charter
            </a>
            ,{" "}
            <a href="https://historicvineyardsociety.org/"
                rel="noreferrer"
                target="_blank">
                the Historic Vineyard Society in California
            </a>
            ,{" "}
            <a href="https://www.savetheold.com/"
                rel="noreferrer"
                target="_blank">
                Lodi’s Save the Old Campaign
            </a>
            ,{" "}
            <a href="https://www.vigno.org/"
                rel="noreferrer"
                target="_blank">
                Maule’s VIGNO project
            </a>
            ,{" "}
            <a href="https://southamericawineguide.com/old-vines-of-south-america-old-vine-register-the-heroic-growers-of-south-america/"
                rel="noreferrer"
                target="_blank">
                South America's Heroic Growers
            </a>
            ,{" "}
            <a href="https://mclarenvalewine.au/index.php?p=wine/old-vine-register"
                rel="noreferrer"
                target="_blank">
                the McLaren Vale Grape Wine & Tourism Associations Old Vine Register
            </a>
            {" "}and {" "}
            <a href="https://www.francsdepied.mc/en/index?p=wine/old-vine-register"
                rel="noreferrer"
                target="_blank">
                the Francs de Pied group
            </a>
            .
        </p>
        <p className="description-text">
            The launch of the Old Vine Conference provoked a lot of interest in the Old
            Vine Registry, and it got to the point where all parties involved realised they
            needed something more searchable, more accessible, more elegant, and more
            useful. The project needed to become an online database, somewhere people
            could go to find old vineyards, learn about them, track down the wines made
            from them and the people farming them. With the help of Alder Yarrow, author
            of Vinography.Com and the longest-running US-based columnist for

            JancisRobinson.com (who handily has a background in digital marketing and
            user experience), funding was found, talented developers were tracked down, and
            under Yarrow’s direction, the real Old Vine Registry was born.
        </p>
        <p className="description-text">
            The purpose of the Old Vine Registry has evolved over the years. Today it is
            intended to be a valuable tool for wine lovers, winegrowers and the wine trade
            alike. The two primary goals are: to be the single, richest source of the most
            accurate, in-depth data on old-vine vineyards in the world; and to connect people
            with vineyards in a way that, directly and indirectly, ensures their preservation. In
            particular, through encouraging people to find and purchase wines made from
            these vineyards.
        </p>
        <div className="sub-headline u-mt-medium">How to Use the Registry</div>
        <p className="description-text">
            The hope is that this site will be used by wine and viticultural students, academic
            and teaching organisations for educational and research purposes; by wine lovers
            to enhance their knowledge, understanding and appreciation for the wines they
            are drinking, and to inspire them to seek out more old-vine wines; to connect
            those who buy wine with those who steward the vineyards; to create a community
            passionate about these vineyards and wines who will eagerly spread the word; and
            to highlight the value of old vines in order to enhance their economic value and
            provide some return on the investment that winegrowers put into these vineyards.
        </p>
        <div className="sub-headline u-mt-medium">How to Contribute</div>
        <p className="description-text">
            Everyone is encouraged to contribute to this crowd-sourced effort. Whether it’s
            your old vineyard or an old vineyard you know about, if you cannot find it in the
            database,{" "}
            <a href="http://www.oldvineregistry.org/submit"
                rel="internal"
            >
                please use our online form to submit suggestions for additions to the registry
            </a>
            . Please provide as much information as possible so this database can be
            kept dynamic, alive, up-to-date, and growing.
        </p>
        <p className="description-text">
            Corrections and updates to existing information are also appreciated. If you find
            inaccuracies or missing information, you are encouraged to{" "}
            <a href="http://www.oldvinesregistry.org/feedback"
                rel="internal"
            >
                send feedback
            </a>
            .
        </p>
        <p className="description-text">
            For more information about the Old Vine Registry, please see our  {" "} <span className="description-text-link" 
                onClick={() => navigate(Paths.faq)}>FAQs</span>
        </p>
        <div className="sub-headline u-mt-medium">Thanks and Appreciation</div>
        <p className="description-text">
            Huge thanks and appreciation must be offered to:
        </p>
        <p className="description-text">
            Jancis Robinson – for being one of the first international wine writers to
            recognise the value of old vines, for believing in old vines, writing about them for
            so many years, harbouring the Old Vine Registry for 13 years, and for being so
            willing to share all this amazing data that has been collected.
        </p>
        <p className="description-text">
            Tamlyn Currin – for her collaboration with Jancis to shepherd the Old Vine
            Registry from idea to reality, which included gathering many of the early entries
            for the registry as well as authoring regular articles to showcase and promote the
            evolution of the project.
        </p>
        <p className="description-text">
            Sarah Abbott MW – for being the driving force behind gathering the wine trade
            together, connecting people and turning talk into action, with the unfailing support 
            of her Consultant Head of Marketing for the Old Vine Conference, Belinda Stone.
        </p>
        <p className="description-text">
            Alder Yarrow – without whom this online OVR would not exist. Alder has been
            the architect, project manager, business consultant, software guru and people
            manager, pouring his talent, skills and experience as well as uncountable hours
            into this.
        </p>
        <p className="description-text">
            Benjamin Roelfs – for the last couple of years, Benjamin has volunteered his time
            and painstakingly collected and assembled well over a thousand entries. The OVR
            more than doubled in size under his stewardship.
        </p>
        <p className="description-text">
            Christo Spies, Ulrich Enslin and their colleagues – for the technology
            development support and expertise required to bring this website to life.
        </p>
        <p className="description-text">
            Lauren Bessen - for her work designing the website.
        </p>
        <div className="sub-headline u-mt-medium">Sponsors</div>
        <p className="description-text">
            The launch of the OVR was made possible by a generous grant from Jackson
            Family Wines.
        </p>
        <div className="sponsor-layout">
            <div className="sponsor-image">
                <a href="https://www.jacksonfamilywines.com/"
                rel="noreferrer"
                target="_blank"
                >
                    <Image src={JFWLogo} fluid={true} />
                </a>
            </div>
            <a href="https://www.oldvines.org/"
            rel="noreferrer"
            target="_blank"
            >
            <SmallerSponsorImage src={OldVineConference} />
            </a>
            <a href="https://www.jancisrobinson.com/"
            rel="noreferrer"
            target="_blank"
            >
            <SmallerSponsorImage src={JRDotCom} />
            </a>
            <a href="https://www.vinography.com/"
            rel="noreferrer"
            target="_blank"
            >
            <SmallerSponsorImage src={Vinography} />
            </a>
        </div>
        <p className="description-text">
            If you are interested in becoming a sponsor of the OVR please use the{" "}
            <a href="https://www.oldvineregistry.org/feedback"
                rel="internal"
            >
                feedback form
            </a>
            {" "}or send an e-mail to{" "}
            <a href="mailto:belinda@oldvines.org">
                belinda@oldvines.org
            </a>
            .
        </p>
        <div className="sub-headline u-mt-medium">Donate</div>
        <p className="description-text">
            <a href="https://www.oldvines.org/checkout/donate?donatePageId=6462a1da9d9f251340b8f1b2"
                rel="internal"
            >
                Financial donations of any size are welcome at any time from both individuals
                and organisations.
            </a>
        </p>
        </div>
    </div>
  );
};

import "./TableHeader.scss";
import { SortOrder } from "./table";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { Box } from "../styled-system/Box";
import React from "react";

export interface TableHeaderProps {
  field?: string;
  sortByField?: string;
  sortOrder?: SortOrder;
  onOrderChange?: (field: string, sortOrder: SortOrder) => void;
  width?: string;
  children?: React.ReactNode;
}

const SortDirection = (props: { sortOrder: SortOrder }) => {
  if (props.sortOrder === "asc") {
    return <BsArrowUpShort />;
  }
  if (props.sortOrder === "desc") {
    return <BsArrowDownShort />;
  }
  return <Box width="1.8rem" display="inline-block" />;
};

export const TableHeader = ({
  field = "",
  sortByField,
  sortOrder = "none",
  onOrderChange,
  width,
  children,
}: TableHeaderProps) => {
  let thStyle = {};
  if (width) {
    thStyle["width"] = width;
  }
  const so = field === sortByField ? sortOrder : "none";
  return (
    <th
      style={thStyle}
      onClick={() => {
        const nextSortOrder =
          sortOrder === "asc" ? "desc" : sortOrder === "desc" ? "none" : "asc";
        if (onOrderChange) {
          onOrderChange(field || "none", nextSortOrder);
        }
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        className="cursor-pointer"
        height="64px"
      >
        {children} <SortDirection sortOrder={so} />
      </Box>
    </th>
  );
};

import React from "react";
import { Guard, GuardResultType } from "./guard";
import { Navigate } from "react-router-dom";

export interface GuardedParams {
  guards: Guard[];
  children: React.ReactNode;
}

export const Guarded = ({ guards, children }: GuardedParams) => {
  for (const guard of guards) {
    const guardResult = guard();
    switch (guardResult.type) {
      case GuardResultType.Continue:
        continue;

      case GuardResultType.Redirect:
        if (!guardResult.redirect) {
          console.error("Redirect not set for guard result");
          return <></>;
        }
        return <Navigate to={guardResult.redirect} />;

      case GuardResultType.AlternativeComponent:
        if (!guardResult.component) {
          console.error("Component not set for guard result");
          return <></>;
        }
        return guardResult.component;
    }
  }

  return <>{children}</>;
};

import { Box } from "../../components/styled-system/Box";

export const ThankYouPage = () => {
  return (
    <Box className="submit-layout" maxWidth="1000px">
      <div className="title-large">Thank you</div>
      <p className="description-text u-mt-medium">
        Your submission has been received. If a follow-up is required, someone
        may contact you. Please remember that the Old Vine Registry is a
        volunteer effort that passionate (but very busy) people maintain in
        their spare time.
      </p>

      <Box height="150px" />
    </Box>
  );
};

import { GuardResult, Guards } from "../components/guard/guard";
import { store } from "../app/store";
import { Paths } from "../paths";

export function authGuard(): GuardResult {
  if (store.getState().auth.bearerToken) {
    return Guards.Continue;
  }
  return Guards.redirect(Paths.login);
}

import { useField } from "formik";

export const Label = (props: {
  field: string;
  label: string;
  className?: string;
}) => {
  const [, meta] = useField(props.field);
  return (
    <label htmlFor={props.field} className={props.className}>
      <span
        className={`form-label ${
          meta.error && meta.touched ? "form-label-error" : ""
        }`}
      >
        {props.label}
      </span>
    </label>
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";

export enum LoginStatus {
  Busy,
  Idle,
  Failed,
}

export interface AuthState {
  username?: string;
  loginStatus: LoginStatus;
  bearerToken?: string;
}

const initialState: AuthState = {
  username: undefined,
  loginStatus: LoginStatus.Idle,
  bearerToken: undefined,
};

export const login = createAsyncThunk<string, void, { rejectValue: string }>(
  "dq/userInfo",
  async (_, thunkAPI) => {
    try {
      const resp = await axios.get<string>(
        process.env.REACT_APP_BACK_END_URL || "/api/login"
      );
      return resp.data;
    } catch (e: any) {
      console.error("login error", e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setBearerToken: (state, action) => {
      state.bearerToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loginStatus = LoginStatus.Busy;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginStatus = LoginStatus.Idle;
      state.bearerToken = action.payload;
    });
    builder.addCase(login.rejected, (state) => {
      state.loginStatus = LoginStatus.Failed;
      // state.userInfoFailureMessage = probe(action.payload) || "";
    });
  },
});

export const authActions = { ...authSlice.actions, login };
export const authReducer = authSlice.reducer;

//Selectors
export const authSelectors = {
  selectBearerToken: (state: RootState) => state.auth.bearerToken,
  selectUsername: (state: RootState) => state.auth.username,
};

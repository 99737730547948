import styled from "styled-components";
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";
import React, { forwardRef } from "react";

const Styledbox = styled.div`
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${space}
`;

export interface BoxProps
  extends ColorProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    SpaceProps {
  children?: React.ReactNode;
  className?: string;
}
export const Box = forwardRef(({ children, ...props }: BoxProps, ref) => (
  <Styledbox ref={ref} {...(props as any)}>
    {children}
  </Styledbox>
));

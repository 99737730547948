import { Field, useField } from "formik";

export const FormInput = (props: any) => {
  const [, meta] = useField(props.name);
  let className = meta.error && meta.touched ? "input-field-error" : "";
  if (props.className) {
    className += " " + props.className;
  }

  return <Field {...props} className={className}></Field>;
};

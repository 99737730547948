export enum GuardResultType {
  Continue,
  Redirect,
  AlternativeComponent,
}

export interface GuardResult {
  type: GuardResultType;
  redirect?: string;
  component?: JSX.Element;
}

export type Guard = () => GuardResult;

export namespace Guards {
  export const Continue: GuardResult = { type: GuardResultType.Continue };

  export function redirect(path: string): GuardResult {
    return {
      type: GuardResultType.Redirect,
      redirect: path,
    };
  }

  export function alternativeComponent(component: JSX.Element): GuardResult {
    return {
      type: GuardResultType.AlternativeComponent,
      component,
    };
  }
}

import "./OldVinesInfoPage.scss";
import WhyOldVines from "../../images/whyoldvines.jpg";
import styled from "styled-components";
import OldVines from "../../images/Old_Vines_Spain_What_Are.jpg";
import Image from "react-bootstrap/Image";
import { Box } from "../../components/styled-system/Box";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../paths";

const PicturePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40rem;
  padding: 1rem;
  position: relative; /* Ensure the div positions its children correctly */
  color: white; /* Ensure text is readable over the background */
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${WhyOldVines});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(80%);
  z-index: 0;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1; /* Ensure the text is above the background image */
`;

export const OldVinesInfoPage = () => {
  const navigate = useNavigate();

  return (
    <div>
        <div>
        <PicturePanel>
          <BackgroundImage />
          <TextContainer className="alegreya-heading-white">
            Why Old Vines?
          </TextContainer>
        </PicturePanel>
        </div>
      <div className="info-layout">
        <p className="description-text u-mt-large">
            Two important questions underpin this project. Why do old vines matter? And
            how do we protect them?
        </p>
        <p className="description-text">
            There are two answers to the first question. Primarily, saving old vines is not a
            romantic endeavour, it is addressing a planetary crisis. It’s a question of
            sustainability, first and foremost. Old vines carry the secrets of survival and have
            adapted to stress over decades and even centuries. They are often more resilient in
            times of drought and heatwave, they have learnt to live with or develop immunity
            to pests and diseases, they have survived extreme weather events.
        </p>
        <p className="description-text">
            Old vines are also much more adapted to the soils they’re planted in and therefore
            more resilient. Unlike young vines, they don’t need irrigating and they need less, if
            any, chemical inputs to survive. Old vineyards are also often full of clonal
            diversity and rare varieties. The genes of old vines can be studied, and old-vine
            material can be propagated for more resilient young vines.
        </p>
        <p className="description-text">
            In addition, these big, gnarly vines are significant reservoirs of biomass and
            carbon; they play vital roles in local hydrological cycles; their old, deep-and-wide
            root networks are inextricably bound up with and connected to the mycorrhizal
            networks that sustain, feed and protect our soils. Old vines are also linked to social
            sustainability in terms of retaining, preserving, celebrating, recognising, respecting
            and dignifying tradition, old wisdom, handed-down knowledge, experience and
            the people who are an integral part of that.
        </p>
        <Box mt="20px" mb="20px">
          <Image src={OldVines} fluid={true} />
        </Box>
        <p className="description-text">
            The second answer is more romantic. These vineyards represent history; they are
            family heirlooms; they are the stories of places and people. Vines, a bit like trees,
            capture our hearts. They lock us into particular places, moments, memories, a
            sense of eternity and permanence. They anchor us to places, belonging. And, of
            course, they often are the source of the most delicious wines.
        </p>
        <p className="description-text">
            How do we protect them? The blunt answer is ‘monetise’. Old vines cost more.
            They also have more value. We need to value old-vine wine more than we do.
            Governments need to stop funding rip-out schemes that replace older vines with
            young vines that need irrigating for many years (irrigation systems which may not
            be viable in the future). Governments, businesses, organisations and academic
            institutions need to fund protection schemes, tax-break incentives, supportive

            education and training for winegrowers, trials and research. Wine producers need
            to pay more for old-vine grapes; importers, distributors, retailers and consumers
            need to all be willing to pay more for old-vine wines.
        </p>
        <p className="description-text">
            Our hope is that the Old Vine Registry will build a community of people and
            places, and energise a global ecosystem that sets this in motion.
        </p>
        <div className="sub-headline u-mt-medium">Criteria for inclusion in the OVR</div>
        <p className="description-text">
        In order to be included in this registry, vineyards must meet the 2024 official OIV definition of "Old Vines." At least 85% of the vines in a given vineyard plot must be at least 35 years old, or if the vines have been grafted, those grafts must have been in place for 35 years. We recognize that exact planting and grafting dates are not always known, so we accept good-faith estimates of these dates by knowledgeable parties. This 35-year minimum age requirement matches the criteria established by several related organisations around the globe, including{" "}
            <a href="https://www.oldvines.org/"
              rel="noreferrer"
              target="_blank"
            >
                The Old Vine Conference
            </a>
            , South Africa’s{" "}
            <a href="https://oldvineproject.co.za/"
              rel="noreferrer"
              target="_blank"
            >
              Old Vine Project
            </a>
            ,{" "}
            <a href="https://barossawine.com/vineyards/old-vine-charter/"
              rel="noreferrer"
              target="_blank"
            >
              Barossa Valley’s Old Vine Charter
            </a>
            , and more.
        </p>
        <p className="description-text">
            For more information about the Old Vine Registry, please see our  {" "} <span className="description-text-link" 
                onClick={() => navigate(Paths.faq)}>FAQs</span>
        </p>
      </div>
    </div>
  );
};

import {Accordion} from './Accordian';
import "./FaqPage.scss";
import { NavigationLink } from "../../components/styled-system/NavigationLink";
import { Paths } from "../../paths";
import { useEffect } from 'react';

export const FaqPage = () => {
  useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        // Set the title when the component mounts
        document.title = 'Old Vine Registry FAQ';
        
        // Clean up the title when the component unmounts
        return () => {
          document.title = 'Old Vine Registry';
        };
      }, []); // Empty dependency array ensures this effect runs only once
    
      const general = [
        {
          title: 'What is an old-vine vineyard?',
          content: (<p>We have adopted the definition of an old vine vineyard as specified in the  OIV Resolution OIV-VITI 703-2024 'OIV definition and recommendations about old grapevines and old vineyards in the vitivinicultural sector.'<br/><br/> This definition reads as follows:<br/><br/>An OLD GRAPEVINE is a single plant officially documented to be 35 years or older regardless of any other factors. It is the result of a physiological/environmental process that occurred over the time, either naturally or purposefully managed (human factors) enabling it to survive in a given place. In the case of grafted plants, the graft connection between rootstock and scion should have been undisturbed for, at least 35 years. <br/><br/>An OLD VINEYARD is a block of vineyard terrain, continuous and legally delimited, where at least 85% of the grapevines respond to the previous definition, and which produces vitivinicultural products (wine, grapes for fresh consumption, dried grapes, distillates and unfermented products from grapevine) eligible for legal certification by a competent authority. Its genetic, economic, sociocultural, and historical factors lend recognizable characteristics to its grapes and respective wines.</p>)
        },
        {
          title: 'Why do old vines matter?',
          content: 'As well as representing historical, cultural and community value, and producing great wine, old vines have survived many challenges over a long period of time. This means that, from a scientific perspective, we can discover and learn a great deal about vineyard resilience, survival and adaptability from studying individual plant genetic make-up, vineyard genetic diversity, physiology, location, viticultural treatment/s and practices, soil and geology, and surrounding environment. This develops our toolbox for planting and managing vineyards for the future. But preserving old vines is also an important environmental act: old vines are carbon reservoirs and have become an integral, well-established part of the local ecosystem. By ripping them out to plant new vines, we lose carbon reservoirs, lose carbon through the ploughing and disruption of the soil, cause immense damage to an established ecosystem, lose biodiversity and important genetic material, damage the soil and disrupt microbial processes.'
        },
        {
          title: 'Why was the OVR (Old Vine Registry) created?',
          content: "A number of people working in different roles in the wine industry and from all around the world have recognised the importance of old vines for the reasons mentioned above. Different conservation initiatives, started by different groups, have been ongoing for a long time, but we all recognised the need for a centralised source of data, bringing groups together, but also providing one place where anyone from anywhere can access information on old vines. As W Edwards Deming famously said, 'Without data, you're just another person with an opinion'."
        },
        {
          title: 'Who created the OVR?',
          content: (<p>The OVR is a collaborative project with many people involved in its creation, one way or another, from inception to data gathering, sponsorship, project management, design and technical build. You can find out more about the people and organisations involved <NavigationLink className="navigationlink" to={Paths.about}>here</NavigationLink>.</p>)
        },
        {
          title: 'When was the website launched?',
          content: (<p>This website was launched on June 26, 2023. You can <a href="https://www.youtube.com/watch?v=fsD7AXpxnh0" target="_blank" rel="noopener noreferrer">view the launch webinar here</a></p>)
        },
        {
          title: 'How many vineyards have you registered?',
          content: "As of Sunday, December 1, 2024, there were 3502 vineyards registered from 39 different countries."
        },
        {
          title: 'Are vineyards in the OVR officially certified?',
          content: (<p>While some vineyards in the Old Vine Registry database are certified by other organizations, such as the California <a href="https://historicvineyardsociety.org" target="_blank" rel="noopener noreferrer">Historic Vineyard Society</a> (and are clearly marked as such), inclusion in the Old Vine Registry does not constitute a certification of any kind. We ask all submitters of vineyard information to confirm that to the best of their knowledge the information provided is accurate and truthful. 
          </p>)
        },
        {
          title: 'Does inclusion in the OVR imply a recommendation of quality for wines produced from that vineyard or the producers of those wines?',
          content: "No. The fact that a vineyard is listed in the Registry does not equate to a recommendation, assessment of quality, or any other endorsement by either the Old Vine Registry, the Old Vine Conference, Jancis Robinson, or any of the parties that have contributed to the registry over time."
        },
        {
          title: 'How did you get all the data that is in the current registry?',
          content: "The data currently populating the Old Vine Registry is a mix of data compiled over the past 11 years by volunteers working on the Registry as well as data directly submitted by members of the public and the wine trade since the launch of the Old Vine Registry."
        },
        {
          title: 'Who owns the OVR?',
          content: (<p>The Old Vine Registry is owned by the <a href="https://www.oldvines.org" target="_blank" rel="noopener noreferrer">Old Vine Conference</a>, a UK-based, not-for-profit organization dedicated to evangelizing and supporting old vine vineyards around the world, the people who farm them, and the wines they produce.</p>)
        },
        {
          title: 'How is the OVR funded?',
          content: (<p>The creation of the Old Vine Registry in its current form was made possible by a generous grant from <a href="https://www.jacksonfamilywines.com" target="_blank" rel="noopener noreferrer">Jackson Family Wines</a>. We welcome support from any and all other organizations and individuals. You can <a href="https://www.oldvines.org/checkout/donate?donatePageId=6462a1da9d9f251340b8f1b2" target="_blank" rel="noopener noreferrer">make a donation here.</a></p>)
        },
        {
          title: 'Can I donate to support the OVR?',
          content: (<p>Yes! Even the smallest donation helps. Please use our <a href="https://www.oldvines.org/checkout/donate?donatePageId=6462a1da9d9f251340b8f1b2" target="_blank" rel="noopener noreferrer">online form</a> to process your donation.</p>)
        }        
      ];

      const searching = [
        {
          title: 'What kind of data can I search on, and how does it match vineyard records?',
          content: 'The site search attempts to match your search query against the text found in the following fields contained within each vineyard record: Country, Region, Subregion, Sub-subregion, Vineyard Name, Producer, Person of Interest, Grape Varieties, and Notes. The matching is a strict keyword match, with no fuzzy logic, which means we cannot currently compensate for misspellings (either in our data or in your search query) or synonyms. The search is performed on a case-insensitive basis, and ignores special characters such as accent marks (i.e. you do not have to type “Mourvèdre,” you can just type “Mourvedre.”)'
        },
        {
          title: 'How does the site determine what results to return?',
          content: 'The search results are prioritized based on which fields your search query matches, with priority being given to, in order of preference, Vineyard Name, Producer, Person of Interest, Country, Region, Subregion, Subsubregion, Notes, and Grape Varieties.'
        },
        {
          title: 'When I search for the name of a country, I get vineyards from other countries, why is that?',
          content: (<p>Because our search strictly matches the text that it finds in each vineyard record, any vineyard record that contains the name of a country somewhere within the record will show up in a search result. If the note for a particular vineyard in California includes the fact that the vineyard was planted using vine cuttings from France, that vineyard will show up in the search results when you type “France” into the search field. <br/><br/> If you are interested in seeing only vineyards from a specific country, use the “Browse by:” functionality on the home page and select the country of interest from the dropdown menu.</p>)
        }
      ]

      const vineyardpages = [
        {
          title: 'Where do the photos come from that appear on vineyard pages?',
          content: 'The vineyard photos are taken from the publicly available media library maintained by Wikimedia Commons. These photos are part of the public domain and are not rights-protected.'
        },
        {
          title: 'How do I add/update/change a photo on a vineyard page?',
          content: (<p>In order to add a photo to a vineyard listing, it must first appear on Wikimedia Commons. To get an image onto Wikimedia Commons, you must first have a free account, AND you must have the rights to the image that you upload. This means the image must either be your own work (i.e. you are the photographer) or you must be able to prove that you are the owner of all rights to the photograph. Note that in most countries, commissioning a photographer to take a picture for you (paid or unpaid) does not mean that you now own the rights to that work. <br/><br/>Once a photo is on Wikimedia Commons, you can send the URL of the photo page to the Old Vine Registry, either during the <NavigationLink className="navigationlink" to={Paths.submit}>submission of a vineyard</NavigationLink>, or by simply sending an e-mail to <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com.</a>.</p>
          )
        },
        {
          title: 'How does the “Find Wines from This Vineyard” button work?',
          content: 'The “Find Wines” button is programmed to do a keyword search on Wine-Searcher.Com using the name of the vineyard, exactly how it appears at the top of the Vineyard Page. I.e. if the vineyard name is “Spottswoode Estate” the Find Wines button will perform a search for “Spottswoode Estate” on Wine Searcher, irrespective of whether such a search will return any results.'
        },
        {
          title: 'The “Find Wines” button doesn’t lead to any/good results. Why not?',
          content: 'Sometimes the name of the vineyard does not appear in the name of the wines that are produced from that vineyard. Sometimes the name of the vineyard is not unique or simply cannot be properly matched by Wine Searcher.'
        },
        {
          title: 'Can you change the search parameters for the Find Wines button?',
          content: (<p>Yes. If you are aware of a better set of search parameters that matches (or improves the match for) a wine on Wine Searcher, please let us know <NavigationLink className="navigationlink" to={Paths.feedback}>using the feedback form</NavigationLink> or by sending an e-mail to <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com.</a></p>)
        }
      ]

      const submission = [
        {
          title: ' How do I submit a vineyard(s) to be included in the OVR?',
          content: (<p>Individual vineyards can be submitted using <NavigationLink className="navigationlink" to={Paths.submit}>our online submission form</NavigationLink>. If you would like to submit multiple vineyards (or if you prefer to use Excel rather than the online form) you can enter data into <a href="https://d.pr/f/05tYQZ" target="_blank" rel="noopener noreferrer">this data-entry spreadsheet</a> and then e-mail it to <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com</a>.</p>)
        },
        {
          title: 'What is the minimum data you need about a vineyard for it to be listed?',
          content: (<p>In order to submit a vineyard for inclusion in the Registry, you must be able to provide the following minimum pieces of data about the vineyard:<br/><br/>
                  Country and Region<br/>
                  Vineyard Name<br/>
                  Date Planted (exact or estimated)<br/>
                  Own-Rooted? (Yes/No)<br/>
                  Size of Vineyard (acres/hectares)<br/>
                  Owner Name</p>)
        },
        {
          title: 'What information should I include regarding the location of the vineyard?',
          content: (<p>Beyond the country itself, we are interested in any and all geographical indications of the vineyard’s location vis a vis the existing wine laws and regulations of that country. To that end, please provide the name of the wine region within which the vineyard is classified, as well as any sub-regional designations such as Appellations (AOC), PDOs, PGIs, DOs, etc. We are looking for the data that is usually required by governments to appear on the front or back of the wine label, such as “Saint-Émilion Grand Cru, Libornais, Bordeaux, France” or “Bolgheri, Tuscany, Italy.”<br/><br/>Do not include the name of the town, principality, county, or province unless those names correspond to official regional or location designations that might appear on the wine label.</p>)
        },
        {
          title: 'What do you mean by “planting date” when it comes to the age of the vineyard?',
          content: (<p>We care about the age of the living vines currently in the vineyard. That is different than the date when vines were first planted in that particular location. While a vineyard might have been first established in 1796, if the oldest currently living vines in the vineyard were planted in 1918, then the planting date should be 1918.<br/><br/>In order to qualify for inclusion in the registry, at least 85% of the vines in a given vineyard should have been planted on or before the vineyard's planting date, whether that date is estimated or exact. This requirement is in accordance with the 2024 OIV definition for old-vine vineyards.</p>)
        },
        {
          title: 'What if I don’t know the date that the vineyard was planted?',
          content: 'We understand that many vineyards do not have exact documentation for their planting date. Please make your best estimate of the planting date. If you are submitting an estimated date, please indicate that it is an estimate using the appropriate check box on the submission form. '
        },
        {
          title: 'Why do you care about vines being own-rooted / franc-de-pied?',
          content: 'Own-rooted or ungrafted vines are uncommon and represent an even more precious commodity than old vines themselves. We believe that this information is an important piece of data about each vineyard. Grafted vines in which the grafts were made less than 35 years ago do not qualify as old vines under the 2024 OIV definition'
        },
        {
          title: 'What if I don’t know if the vines are own-rooted?',
          content: 'If you are not sure whether the vines are own-rooted, then please select the  “No” option.'
        },
        {
          title: 'What if the owner of the vineyard doesn’t want their name publicly shown on a vineyard listing?',
          content: 'We believe it is useful to have the owner of the vineyard available for public consumption. However, if there are privacy concerns, it is acceptable to use “unknown” or “not provided” as the value for the name of the vineyard owner when submitting a vineyard.'
        },
        {
          title: 'Who qualifies for being a person of significance?',
          content: 'There is no strict qualification for who can be listed as a person of significance. The name of the person who planted the vineyard, the original owner of the estate, the namesake of the vineyard, or the name of the person who farms the vineyard are a few examples of who you might include as part of this data field. There is no need to repeat the name of the vineyard owner if it was already supplied elsewhere on the form.'
        },
        {
          title: 'What information should I provide about the vineyard in the notes field?',
          content: 'Basically, anything that is not covered by the other data fields in the form. You can include information about wines produced from the vineyard (including where they are sold), stories, history and myths about the vineyards, interesting facts, links to articles. Please provide as much information as possible! '
        },
        {
          title: 'Why do you ask for the latitude and longitude of the vineyard?',
          content: 'If we know the latitude and longitude of a vineyard, we can provide users a satellite image of the vineyard and its surroundings using Google Maps or Google Earth. This information is not required in order to submit a vineyard, however. '
        },
        {
          title: 'How do I know the latitude and longitude of the vineyard, and how do I make sure it is formatted correctly?',
          content: (<p>You can determine the latitude and longitude of any location in <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">Google Maps</a> by right-clicking on a place or area on the map. This will bring up a small panel of information. The first item in this list will be a set of latitude and longitude coordinates formatted in decimal form, e.g. 37.817283945882565, -122.25734901596341. Clicking on that set of coordinates will copy that information to the clipboard.<br/><br/>
          Please make sure your coordinates do not look like this: 48° 51' 52.9776'' N, 2° 20' 56.4504'' E. If you only have coordinates in this GPS style (with degrees minutes and seconds) you can use those to search for the location on Google Maps, and then convert them to decimal form using the instructions above.
          </p>)
        },
        {
          title: 'What happens after I submit a vineyard?',
          content: 'The data you submit is e-mailed to the site administrator who will review it and enter it into the database.'
        },
        {
          title: 'How long will it take for my vineyard submission to show up on the site?',
          content: 'In most cases, your vineyard will be added to the site within 48 to 72 hours. However, this depends on the availability of a volunteer to process your submission. In some cases, it may take more than a week. Thank you for your patience.'
        },
        {
          title: 'I’m not a native English speaker. Can I submit information in my native language?',
          content: 'Yes, you may submit your information in any language, however, we will rely on computer translation to convert your submission into English, which may result in errors or ambiguities.'
        },
        {
          title: 'What if I want to submit a whole lot of vineyards, is there an easy way to do that?',
          content: (<p>If you would like to submit multiple vineyards you can enter data into <a href="https://d.pr/f/05tYQZ" target="_blank" rel="noopener noreferrer">this data-entry spreadsheet</a> and then email it to <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com</a>.</p>)
        }
      ];
  
      const operational = [
        {
          title: 'Who runs the OVR on a day-to-day basis?',
          content: (<p>At the moment, the OVR is run by Alder Yarrow on a volunteer basis. We welcome any offers from volunteers who may be interested in helping out on a regular, ongoing basis. If you’d like to volunteer, please e-mail <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com</a>.</p>)
        },
        {
          title: 'What is the relationship between the OVR and the Old Vine Conference?',
          content: (<p>The OVR is a central, global data source with one aim: to hold free, accurate, searchable information about all old-vine vineyards. The OVC is a think tank-style initiative with the primary aim of building a category for old-vine wines by raising awareness, by building a strongly connected global community that supports and drives this aim, and by turning interest into economic value. When the idea to create the OVR as a standalone website became a reality, the site needed an organisational home, and the not-for-profit Old Vine Conference was an ideal choice for ownership of the project.</p>)
        }

      ];

      const data = [
        {
          title: 'How often are the data in the registry updated?',
          content: (<p>New vineyards are added as they are submitted, and corrections to existing data are made as they are received. On average we receive several new vineyard submissions per week.</p>)
        },
        {
          title: 'Can I get a copy of the OVR data if I want it? How?',
          content: (<p>Yes, the entire database is available in excel form upon request. Simply send an e-mail to <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com</a>.</p>)
        },
        {
          title: 'How do I correct an existing vineyard entry?',
          content: (<p>Please contact us using the <NavigationLink className="navigationlink" to={Paths.feedback}>feedback form</NavigationLink></p>)
        },
        {
          title: 'Who owns the data and what data protection policy do you have in place?',
          content: (<p>The Old Vine Registry is licensed under a Creative Commons Attribution-NonCommercial 4.0 International License. Please see <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank" rel="noopener noreferrer">here</a> for what this means legally. Please see our <NavigationLink className="navigationlink" to={Paths.privacy}>Privacy Policy</NavigationLink> for additional data protection information.</p>)
        },
        {
          title: 'Can I republish information or photos I find on the OVR?',
          content: (<p>Yes. All vineyard data on the web site is licensed under a Creative Commons Attribution-NonCommercial 4.0 International License. Please see <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank" rel="noopener noreferrer">here</a> for what this means legally. All photos that appear on the Old Vine Registry are in the public domain and can be downloaded from Wikimedia Commons where they are hosted. Click on any image to be taken to the source page on Wikimedia Commons which will provide you with different ways you may download or utilize the image.
            </p>)
        }
        

      ];

      const miscellaneous = [
        {
          title: 'Where can I buy old vine wine?',
          content: (<p>Every vineyard page in the Old Vine Registry links to Wine Searcher in an attempt to provide the option for you to purchase wines from specific old-vine vineyards that you find within the OVR. These searches do not always yield results, however.<br/><br/>
          We recommend establishing a relationship with a fine wine retailer near you. Their staff should be able to guide you to bottles made from old vines.
          </p>)
        },
        {
          title: 'How can I help with the OVR?',
          content: (<p>Please contact us using the <NavigationLink className="navigationlink" to={Paths.feedback}>feedback form</NavigationLink></p>)
        },
        {
          title: 'How do I make a suggestion for how to improve the OVR?',
          content: (<p>Please contact us using the <NavigationLink className="navigationlink" to={Paths.feedback}>feedback form</NavigationLink></p>)
        }
      ];

  return (
    <div className="about-layout">
      <div className="title-large">Frequently Asked Questions (FAQ)</div>

      <div className="table-of-contents u-mt-large">
        <ul>
          <li className="description-text"><a href="#general-section">General</a></li>
          <li className="description-text"><a href="#searching-section">Searching and Browsing</a></li>
          <li className="description-text"><a href="#vineyardpage-section">Vineyard Pages</a></li>
          <li className="description-text"><a href="#submission-section">Vineyard Submission</a></li>
          <li className="description-text"><a href="#operational-section">Operational</a></li>
          <li className="description-text"><a href="#data-section">Data</a></li>
          <li className="description-text"><a href="#miscellaneous-section">Miscellaneous</a></li>
        </ul>
      </div>
      
      <p><div id="general-section" className="sub-headline u-mt-large">General</div>
        <Accordion items={general} />
      </p>

      <p>
        <div id="searching-section" className="sub-headline u-mt-large">Searching and Browsing</div>
        <Accordion items={searching} />
      </p>

      <p>
        <div id="vineyardpage-section" className="sub-headline u-mt-large">Vineyard Pages</div>
        <Accordion items={vineyardpages} />
      </p>

      <p>
        <div id="submission-section" className="sub-headline u-mt-large">Vineyard Submission</div>
        <Accordion items={submission} />
      </p>

      <p>
        <div id="operational-section" className="sub-headline u-mt-large">Operational</div>
        <Accordion items={operational} />
      </p>

      <p>
        <div id="data-section" className="sub-headline u-mt-large">Data</div>
        <Accordion items={data} />
      </p>

      <p>
        <div id="miscellaneous-section" className="sub-headline u-mt-large">Miscellaneous</div>
        <Accordion items={miscellaneous} />
      </p>
    </div>
  );
};

import Contest from "../../images/contest.jpg";
import styled from "styled-components";
import "./Contest.scss";
import { useEffect } from 'react';
import { NavigationLink } from "../../components/styled-system/NavigationLink";
import { Paths } from "../../paths";
import { Image } from "react-bootstrap";
import AcadamieLogo from "../../images/academie_du_vin_logo.png";
import JRLogo from "../../images/jr-dotcom.jpg";
import WOFWLogo from "../../images/wofw-logo.webp";
import WSGLogo from "../../images/WSG_Logo_Dark.png";
import VinousLogo from "../../images/vinous_logo.jpg";

const PicturePanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 40rem;
  padding: 1rem;
  position: relative; /* Ensure the div positions its children correctly */
  color: white; /* Ensure text is readable over the background */
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${Contest});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(80%);
  z-index: 0;
`;

const TextContainer = styled.div`
  position: relative;
  z-index: 1; /* Ensure the text is above the background image */
`;


export const ContestPage = () => {
  useEffect(() => {
        // Set the title when the component mounts
        document.title = 'Old Vine Registry Vine Hunt Contest';
        
        // Clean up the title when the component unmounts
        return () => {
          document.title = 'Old Vine Registry';
        };
      }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div>
        <div>
            <PicturePanel>
                <BackgroundImage />
                <TextContainer className="alegreya-heading-white">
                    Heritage Vine Hunt Contest
                </TextContainer>
            </PicturePanel>
        </div>
        <div className="contest-layout">
        <div className="title-small">Join the Heritage Vine Hunt Contest and help us Register 10,000 Vineyards by 2027</div>
        
        <p className="description-text">
                With over 3,200 old-vine vineyards already catalogued, we’ve made significant strides in capturing some of the breadth of the old-vine universe. But we’re really only getting started. Countless vineyards over 35 years of age, potentially numbering in the tens of thousands, are waiting to be catalogued. We just need your help making that happen.
        </p>
        <p className="description-text">
                We’re excited to introduce the <strong>Heritage Vine Hunt Contest</strong>—a spirited challenge aimed at expanding our registry to 10,000 vineyards by the end of June 2027. Participating is straightforward: just <NavigationLink className="navigationlink" to={Paths.submit}>submit as many qualifying vineyards</NavigationLink>  to our database as you can. Whether you're working in the industry, a wine lover, or just a traveler with a penchant for viticulture, your contributions can help us make this public resource even more valuable.
        </p>
            
        <div className="sub-headline u-mt-medium">How it Works</div>
        <div className="">
        <ul>
                <li className="description-text">Annually, the top three contributors will be rewarded with exceptional prizes (details below) and will receive extensive public acknowledgment for their efforts.</li>
                <li className="description-text">The individual who submits the most total vineyards by our 26 June, 2027, deadline will receive the grand prize.</li>
        </ul>
        </div>
        <p className="description-text"> 
        Need guidance on how to start your vineyard hunt? We’re here to assist! <a href="mailto: oldvineregistry@gmail.com">Contact us via email</a> for expert advice, practical tips, and suggestions on how to hunt down information on old-vine vineyards.
        </p>
            
        <div className="sub-headline u-mt-medium">Contest Prizes</div>
            
        <p className="description-text">
        The grand prize winner and the end of year three will receive:
        </p>
        <div className="">
            <ul>
                <li className="description-text">A travel bursary covering flights, hotels, meals and tickets to attend a future Old Vine Conference Field Trip (past trips have included Jumilla, Sicily, and more)</li>
                <li className="description-text">A lifetime membership to the Old Vine Conference</li> 
                <li className="description-text">A full set of wine maps from Vinous</li> 
                <li className="description-text">A copy of <i>The Essence of Wine</i> by Alder Yarrow</li>
                <li className="description-text">A set of 6 Jancis Robinson x Richard Brendon wine glasses</li>
            </ul>
        </div>
        <p className="description-text">
        The annual winners at the end of the first and second years will receive::
        </p>
        <div className="">
            <ul>
                <li className="description-text">A 2-year Gold Membership to JancisRobinson.com</li>
                <li className="description-text">A 2-year membership to Wine Scholar Guild</li>
                <li className="description-text">A year’s subscription to <i>World of Fine Wine</i> magazine</li>
                <li className="description-text">A selection of titles from the Academie du Vin Library</li>
                <li className="description-text">A year’s individual membership to the Old Vine Conference</li>
            </ul>
        </div>

        <p className="description-text">
        The annual second-place contestant s at the end of the first and second years will receive:
        </p>
        <div className="">
            <ul>
                <li className="description-text">A set of 2 Jancis Robinson x Richard Brendon wine glasses</li>
                <li className="description-text">A year’s individual membership to the Old Vine Conference</li>
            </ul>
        </div>
        <p className="description-text">
        The annual third-place contestants at the end of the first and second years will receive:
        </p>
        <div className="">
            <ul>
                <li className="description-text">A year’s individual membership to the Old Vine Conference</li>
            </ul>
        </div>
        <p className="description-text">
            <b>Helpful suggestions for contestants</b>
        </p>
        <div className="">
            <ul>
                <li className="description-text">Make sure to <NavigationLink className="navigationlink" to={Paths.vineyards}>search the database</NavigationLink> to make sure that a vineyard is not already catalogued before submitting it</li>
                <li className="description-text">Instead of using our online form to submit vineyards one at a time, you’ll find it much easier to submit vineyards using our Excel data entry template, which you can <a href="https://d.pr/f/LxfmaA" target="_blank" rel="noreferrer">download here</a></li>
                <li className="description-text">Regions that are particularly under-represented include Rioja, Ribeira Sacra, Bierzo, Châteauneuf-du-Pape, the northern Rhône, Burgundy, Austria, Germany, Tokaj, Santorini, Etna, Beaujolais, and more</li>
                <li className="description-text">We’re happy to give you specific ideas for how to begin and conduct your research</li>
            </ul>
        </div>
        <br/>
        <div className="title-small">Prizes Sponsored By</div>
        <div className="sponsor-layout">
            <div className="contest-sponsor-image">
                <a href="https://www.winescholarguild.com/"
                rel="noreferrer"
                target="_blank"
                >
                    <Image src={WSGLogo} fluid={true} />
                </a>
            </div>
        </div>
        <p className="description-text">Since 2005, <b>Wine Scholar Guild</b> has championed authentic wine and the people who make them through immersive online, in-person and study abroad programs. WSG Members gain access to live weekly events, hundreds of hours of video content and written resources created by leading wine authorities. WSG members also join an active global community of serious enthusiasts and wine professionals seeking to keep their wine education in constant motion.</p>
        <div className="sponsor-layout">
            <div className="contest-sponsor-image">
                <a href="https://www.jancisrobinson.com/"
                rel="noreferrer"
                target="_blank"
                >
                    <Image src={JRLogo} fluid={true} />
                </a>
            </div>
        </div>
        <p className="description-text"><b>JancisRobinson.Com</b> is the Wine knowledge platform by <b>Jancis Robinson</b>. From beginners to experts, learn more about wine with articles, tasting notes, and more. Jancis was voted the world's most influential wine critic in various polls in the US, France and internationally in 2018 – although she describes herself as a wine writer rather than a wine critic. </p>
        <div className="sponsor-layout">
            <div className="contest-sponsor-image">
                <a href="https://academieduvinlibrary.com/"
                rel="noreferrer"
                target="_blank"
                >
                    <Image src={AcadamieLogo} fluid={true} />
                </a>
            </div>
        </div>       
        
        <p className="description-text">The <b>Académie du Vin Library</b>, founded by Steven Spurrier and friends, is dedicated to publishing the finest wine writing of the past, the present and the future. They are proud to feature a diverse set of acclaimed writers and award-winning books demystifying this complex subject for beginners and experts alike.</p>
        <div className="sponsor-layout">
            <div className="contest-sponsor-image">
                <a href="https://vinous.com/"
                rel="noreferrer"
                target="_blank"
                >
                    <Image src={VinousLogo} fluid={true} />
                </a>
            </div>
        </div>
        
        <p className="description-text">At the intersection of media and technology, <b>Vinous</b> is Antonio Galloni’s vision of a modern-day wine publication. Vinous’s team of renowned critics and writers offers in depth coverage of new releases, retrospectives and verticals of older wines, videos with winemakers, interactive maps, restaurant recommendations and more, all published in a continual, daily stream of articles. </p>
        <div className="sponsor-layout">
            <div className="contest-sponsor-image">
                <a href="https://worldoffinewine.com/"
                rel="noreferrer"
                target="_blank"
                >
                    <Image src={WOFWLogo} fluid={true} />
                </a>
            </div>
        </div>   
        <p className="description-text">Named Louis Roederer International Wine Publication of the Year three years running (2010, 2011 and 2012), <b>The World of Fine Wine</b> is a quarterly award-winning magazine, website and app speaking to an international readership of discerning individuals who each share a passion for wine. </p>
        <br/><br/>
        <div className="title-small">Detailed Rules, Regulations, Terms and Conditions</div>
        <p className="contest-rules">

            <div className="sub-headline u-mt-small">1. Who Can Enter</div>
            
            <p className="description-text">This contest is open to all private individuals worldwide, with the following exceptions:</p>
            <div className="">
                <ul>
                <li className="description-text">Employees Exclusion: Current employees of any governmental agency focused on viticulture or wine, and employees of regional wine marketing associations, consortia, or syndicates, are not eligible. Employees and team members of the Old Vine Conference, the Old Vine Registry, and JancisRobinson.Com are also excluded.</li>
                <li className="description-text">Age Requirement: Participants must be of legal age to enter the contest as per their local laws.</li>
                </ul>
            </div>
            <p className="description-text">By entering, participants confirm they meet these criteria and are not disqualified by any of the above exclusions.</p>

            
            <div className="sub-headline u-mt-small">2. How to Enter</div>
                <p className="description-text">To participate, submit detailed records of vineyards that meet these criteria:</p>
            <div className="">
                <ul>
                    <li className="description-text">Vineyard Age: The vineyard must be at least 35 years old.</li>
                    <li className="description-text">Database Eligibility: The vineyard must not already be listed in the Old Vine Registry's database.</li>
                    <li className="description-text">Mandatory information to be submitted about each vineyard:</li>
                    <ul>
                        <li className="description-text">Location: Include the country, region, and sub-region.</li>
                        <li className="description-text">Vineyard Name: The official name of the vineyard.</li>
                        <li className="description-text">Ownership: Name of the current owner or the winery producing wine from the vineyard.</li>
                        <li className="description-text">Planting Date: The year or exact date when the vineyard was planted.</li>
                        <li className="description-text">Vineyard Size: Total area in acres or hectares (this can be an estimate, and isn't as strictly required as other data)</li>
                    </ul>
                </ul>
            </div>
        <p className="description-text">Incomplete submissions will not be considered. Additional historical context, descriptions, and information in other data fields is encouraged but not required.</p>
        <p className="description-text">Submit entries through <NavigationLink className="navigationlink" to={Paths.submit}>the designated form on the Old Vine Registry website</NavigationLink> or by e-mailing a filled out version of the <a href="https://d.pr/f/LxfmaA" target="_blank" rel="noreferrer">data entry template</a>. We reserve the right to verify all information and reject any inaccurate or incomplete entries.</p>
            
            <div className="sub-headline u-mt-medium">3. Contest Period</div>
            <p className="description-text">The contest runs from 27 June, 2024, at 00:00 GMT to 26 June, 2027, at 23:59 GMT. Entries must be submitted within this period.</p>
            <p className="description-text" ><strong>Interim Awards</strong></p>
            <p className="description-text">Interim awards will be given based on entries received by:</p>
        <div className="">
            <ul>
                <li className="description-text">First Interim Award Deadline: 26 June, 2025, at 23:59 GMT.</li>
        <li className="description-text">Second Interim Award Deadline: 26 June, 2026, at 23:59 GMT.</li>
            </ul>
            </div>
        <p className="description-text">Entries submitted after the first interim deadline but before the second will only be considered for the second interim and final awards.</p>

            <div className="sub-headline u-mt-small">4. Judging Criteria</div>
            <p className="description-text">Entries will be judged based on the number of unique, previously uncatalogued vineyard records submitted by each participant.</p>
            
            <p className="description-text"><strong>Verification and Counting</strong></p>
        <div className="">
            <ul>
                <li className="description-text">The Old Vine Registry team will track and count submissions.
        •	Each submission will be verified to ensure it is not already in the database.</li>
            </ul>
            </div>
            
                    
        <p className="description-text"><strong>Data Consolidation</strong></p>
        <div className="">
            <ul>
                <li className="description-text">We reserve the right to adjust or merge details in submissions to match the registry’s format and standards.</li>
                <li className="description-text">Separate vineyard blocks may be recorded as a single vineyard.</li>
                </ul>
            </div>       
                    
        <p className="description-text"><strong>Determination of Winners</strong></p>
        <div className="">
                <ul>
                    <li className="description-text">Winners will be determined by the total number of valid, unique vineyard records submitted.</li>
                    <li className="description-text">In the event of a tie, the earliest submission date will be the tiebreaker.</li>
                </ul>
                </div>
                
                <div className="sub-headline u-mt-small">5. Prizes</div>
                <p className="description-text">Prizes are non-transferable and cannot be exchanged for cash. Physical items will be shipped to participants at an address provided by them within 60 days following the announcement of winners.</p>
                
                    
                <p className="description-text">
                The grand prize winner and the end of year three will receive:
                </p>
                <div className="">
                    <ul>
                        <li className="description-text">A travel bursary covering flights, hotels, meals and tickets to attend a future Old Vine Conference Field Trip (past trips have included Jumilla, Sicily, and more)</li>
                        <li className="description-text">A lifetime membership to the Old Vine Conference</li> 
                        <li className="description-text">A full set of wine maps from Vinous</li> 
                        <li className="description-text">A copy of <i>The Essence of Wine</i> by Alder Yarrow</li>
                        <li className="description-text">A set of 6 Jancis Robinson x Richard Brendon wine glasses</li>
                    </ul>
                </div>
                <p className="description-text">
                The annual winners at the end of the first and second years will receive::
                </p>
                <div className="">
                    <ul>
                        <li className="description-text">A 2-year Gold Membership to JancisRobinson.com</li>
                        <li className="description-text">A 2-year membership to Wine Scholar Guild</li>
                        <li className="description-text">A year’s subscription to <i>World of Fine Wine</i> magazine</li>
                        <li className="description-text">A selection of titles from the Academie du Vin Library</li>
                        <li className="description-text">A year’s individual membership to the Old Vine Conference</li>
                    </ul>
                </div>

                <p className="description-text">
                The annual second-place contestant s at the end of the first and second years will receive:
                </p>
                <div className="">
                    <ul>
                        <li className="description-text">A set of 2 Jancis Robinson x Richard Brendon wine glasses</li>
                        <li className="description-text">A year’s individual membership to the Old Vine Conference</li>
                    </ul>
                </div>
                <p className="description-text">
                The annual third-place contestants at the end of the first and second years will receive:
                </p>
                <div className="">
                    <ul>
                        <li className="description-text">A year’s individual membership to the Old Vine Conference</li>
                    </ul>
                </div>
                
                <div className="sub-headline u-mt-small">6. Data Protection and Privacy</div>
                <p className="description-text">We are committed to protecting your privacy in accordance with GDPR and our existing <a href="/privacy">Privacy Policy</a>. By participating, you agree to:</p>
                
            <div className="">
                    <ul>
                        <li className="description-text"><strong>Consent for Communication:</strong> Receive communications related to the contest.</li>
                        <li className="description-text"><strong>Publicity Consent:</strong> If you win, your name and submission details may be publicized.</li>
                    </ul>
                </div>
                
                <div className="sub-headline u-mt-small">7. Intellectual Property</div>
                
            <div className="">
                    <ul>
                        <li className="description-text"><strong>Submission and Use of Data:</strong> By submitting data, you grant us the right to use, reproduce, display, and distribute the information.</li>
            <li className="description-text"><strong>Creative Commons Licensing:</strong> Verified data will be published under the Creative Commons Attribution 4.0 International License (CC BY 4.0).</li>
            <li className="description-text"><strong>Ownership:</strong> You do not retain proprietary rights to submitted data; it becomes part of the public domain.</li>
            <li className="description-text"><strong>Responsibility for Rights:</strong> Ensure you have the necessary rights and permissions to submit the data.</li>
                    </ul>
                </div>
        
        
            <div className="sub-headline u-mt-small">8. Disclaimer and Limitation of Liability</div>
            <p className="description-text">We are not responsible for technical failures or lost, stolen, or corrupted data. By participating, you agree to release and hold us harmless from any claims, damages, or liabilities arising from the contest.</p>
            
            <div className="sub-headline u-mt-small">9. Changes and Cancellations</div>
            <p className="description-text">We reserve the right to cancel, modify, or suspend the contest due to unforeseen circumstances. Participants will be notified of any significant changes.</p>
            
            <div className="sub-headline u-mt-small">10. Contact Information</div>
                <p className="description-text">For questions, please email <a href="mailto:oldvineregistry@gmail.com">oldvineregistry@gmail.com.</a></p>
            
            
            <div className="sub-headline u-mt-small">11. Acknowledgment of Understanding</div>
                <p className="description-text">By submitting data, you agree to all the terms and conditions of the contest. Your submission signifies your full and unconditional agreement to these rules and our decisions, which are final and binding.</p>

            <p className="description-text">Thank you for participating in the Heritage Vine Hunt Contest! We thank you in advance for your contributions.</p>
            </p>

        </div>
    </div>
  );
};

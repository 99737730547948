import {
  Feedback,
  Vineyard,
  VineyardsResultPage,
  VineyardSubmission,
} from "./models";
import { SortOrder } from "../components/table/table";
import axios from "axios";
import { Environment } from "../env";
import { LocalDateTime } from "@js-joda/core";
import { store } from "../app/store";
import fileDownload from "js-file-download";

export interface VineyardsQuery {
  searchText?: string;
  searchValue?: string;
  field: string;
  order: SortOrder;
  pageSize: number;
  pageNumber: number;
}

interface VineyardsQueryByAge {
  startYear: number;
  endYear: number;
  field: string;
  order: SortOrder;
  pageSize: number;
  pageNumber: number;
}

interface VineyardsQueryByCountry {
  country: string;
  field: string;
  order: SortOrder;
  pageSize: number;
  pageNumber: number;
}

interface VineyardsQueryByVariety {
  variety: string;
  field: string;
  order: SortOrder;
  pageSize: number;
  pageNumber: number;
}

export async function getVineyards(
  query: VineyardsQuery
): Promise<VineyardsResultPage> {
  try {
    const result = await axios.get(Environment.backEndUrl + "/api/vineyards", {
      params: query,
    });
    return result.data;
  } catch (e) {
    console.error("getVineyards query failed:", e);
  }
  return { content: [], totalElements: 0, totalPages: 0 };
}

export async function getVineyardsByAge(
  query: VineyardsQuery
): Promise<VineyardsResultPage> {
  let startYear = 0;
  let endYear = 0;
  if (query.searchValue === "155+") {
    startYear = 1000;
    endYear = LocalDateTime.now().minusYears(155).year();
  } else if (query.searchValue) {
    const [min, max] = query.searchValue.split("-");
    startYear = LocalDateTime.now().minusYears(+max).year();
    endYear = LocalDateTime.now().minusYears(+min).year();
  }

  const params: VineyardsQueryByAge = {
    startYear,
    endYear,
    field: query.field,
    order: query.order,
    pageSize: query.pageSize,
    pageNumber: query.pageNumber,
  };
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/vineyards/yearRange",
      {
        params,
      }
    );
    return result.data;
  } catch (e) {
    console.error("getVineyards query failed:", e);
  }
  return { content: [], totalElements: 0, totalPages: 0 };
}

export async function getVineyardsByCountry(
  query: VineyardsQuery
): Promise<VineyardsResultPage> {
  let country = query.searchValue ?? "";

  const params: VineyardsQueryByCountry = {
    country: country,
    field: query.field,
    order: query.order,
    pageSize: query.pageSize,
    pageNumber: query.pageNumber,
  };
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/vineyards/country",
      {
        params,
      }
    );
    return result.data;
  } catch (e) {
    console.error("getVineyards query failed:", e);
  }
  return { content: [], totalElements: 0, totalPages: 0 };
}

export async function getVineyardsByVariety(
  query: VineyardsQuery
): Promise<VineyardsResultPage> {
  let variety = query.searchValue ?? "";

  const params: VineyardsQueryByVariety = {
    variety: variety,
    field: query.field,
    order: query.order,
    pageSize: query.pageSize,
    pageNumber: query.pageNumber,
  };
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/vineyards/variety",
      {
        params,
      }
    );
    return result.data;
  } catch (e) {
    console.error("getVineyards query failed:", e);
  }
  return { content: [], totalElements: 0, totalPages: 0 };
}

export async function getVineyard(composite: string): Promise<Vineyard | undefined> {
  try {
    const result = await axios.get(`${Environment.backEndUrl}/api/vineyards/lookup/${composite}`);
    return result.data;
  } catch (e) {
    console.error("getVineyard query failed:", e);
  }
  return undefined;
}

export async function getCountries(): Promise<string[]> {
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/vineyards/countries",
      { headers: { "Content-Type": "application/json" } }
    );
    return result.data.filter((it) => !!it);
  } catch (e) {
    console.error("could not get countries", e);
  }
  return [];
}

export async function getVarieties(): Promise<string[]> {
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/vineyards/varieties",
      { headers: { "Content-Type": "application/json" } }
    );
    return result.data.filter((it) => !!it);
  } catch (e) {
    console.error("could not get cultivars", e);
  }
  return [];
}

export async function getDatabaseInfo(): Promise<string> {
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/databaseInfo"
    );
    return result.data;
  } catch (e) {
    console.error("could not get database info:", e);
  }
  return "";
}


export async function getLastUpdated(): Promise<string> {
  try {
    const result = await axios.get(
      Environment.backEndUrl + "/api/vineyards/lastUpdated"
    );
    return result.data;
  } catch (e) {
    console.error("could not get last updated:", e);
  }
  return "";
}

/*
//only used for test purposes
function sleep(delayMS: number): Promise<undefined> {
  return new Promise((resolve) =>
    setTimeout(() => resolve(undefined), delayMS)
  );
}
//*/

export async function submitVineyard(vineyard: VineyardSubmission) {
  try {
    const result = await axios.post(
      Environment.backEndUrl + "/api/submission",
      vineyard,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return result.data;
  } catch (e) {
    console.error("could not submit vineyard:", e);
  }
  return "";
}

export async function submitFeedback(feedback: Feedback): Promise<String> {
  try {
    const result = await axios.post(
      Environment.backEndUrl + "/api/feedback",
      feedback
    );
    return result.data;
  } catch (e) {
    console.error("could not submit feedback:", e);
  }
  return "";
}

export async function loginUser(
  username: string,
  password: string
): Promise<string> {
  try {
    const result = await axios.post(Environment.backEndUrl + "/api/authenticate", {
      username,
      password,
    });
    return result.data;
  } catch (e) {
    console.error("authentication call failed:", e);
    return "";
  }
}

export async function uploadDatabase(file: File) {
  const token = store.getState().auth.bearerToken;
  console.log("File size (in bytes):", file.size);
  try {
    const result = await axios.post(
      Environment.backEndUrl + "/api/vineyards/upload",
      { file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      }
    );
    return result.data;
  } catch (e) {
    console.error("could not upload database TSV file:", e);
  }
  return "";
}

export async function downloadDatabase() {
  const token = store.getState().auth.bearerToken;
  try {
    const result = await axios.get(Environment.backEndUrl + "/api/downloadTdf", {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    fileDownload(result.data, "old-vine-registry.tsv");
  } catch (e) {
    console.error("could not download database TSV file:", e);
  }
}

export async function downloadSynonyms() {
  const token = store.getState().auth.bearerToken;
  try {
    const result = await axios.get(Environment.backEndUrl + "/api/synonyms", {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    fileDownload(result.data, "synonyms.tsv");
  } catch (e) {
    console.error("could not download synonym TSV file:", e);
  }
}

export async function uploadSynonyms(file: File) {
  const token = store.getState().auth.bearerToken;
  try {
    const result = await axios.post(
      Environment.backEndUrl + "/api/synonyms/upload",
      { file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  } catch (e) {
    console.error("could not upload database TSV file:", e);
  }
  return "";
}

export async function downloadSearchHistory() {
  const token = store.getState().auth.bearerToken;
  try {
    const result = await axios.get(Environment.backEndUrl + "/api/downloadSearchHistoryTdf", {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    fileDownload(result.data, "searchhistory.tsv");
  } catch (e) {
    console.error("could not download synonym TSV file:", e);
  }
}
import "./FeedbackPage.scss";
import { NavigationLink } from "../../components/styled-system/NavigationLink";
import { Paths } from "../../paths";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import { Box } from "../../components/styled-system/Box";
import { Feedback } from "../../api/models";
import { useState, useEffect} from "react";
import { submitFeedback } from "../../api/old-vine-registry-api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Label } from "../../components/form/Label";
import { FormInput } from "../../components/form/FormInput";

const Loading = (props: { loading: boolean }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="25px"
    mt="30px"
  >
    {props.loading && <Spinner animation="border" role="status" />}
  </Box>
);

export const FeedbackPage = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const postFeedback = async (feedback: Feedback) => {
    setSubmitting(true);
    try {
      const result = await submitFeedback(feedback);
      console.log("submit feedback result:", result);
      navigate(Paths.thankYou);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    // Set the title when the component mounts
    document.title = 'Old Vine Registry Feedback';
    
    // Clean up the title when the component unmounts
    return () => {
      document.title = 'Old Vine Registry';
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className="submit-layout">
      <div className="title-large">Provide feedback</div>
      
      <p className="description-text">
        Spotted an error? Want to tell us something? We welcome constructive
        feedback and suggestions at any time. Please use the form below to send
        us a message. As this is a volunteer effort, please be patient. A
        response may be some time in coming. If you would like to submit a
        vineyard to be included in our registry, please{" "}
        <NavigationLink to={Paths.submit}>
          <span className="description-text-link">use this form</span>
        </NavigationLink>
        .
      </p>
      <p className="description-text">
        Before submitting feedback or questions, we encourage you to consult our extensive {" "} <span className="description-text-link" 
              onClick={() => navigate(Paths.faq)}>FAQs</span>.</p>

      <p className="description-text u-mt-large">* required fields</p>
      <Formik
        initialValues={{ name: "", email: "", message: "" } as Feedback}
        validationSchema={Yup.object({
          name: Yup.string().required("Please enter your name"),
          email: Yup.string()
            .required("Please enter your email address")
            .email("Invalid email address"),
          message: Yup.string().required("Please enter a message"),
        })}
        onSubmit={postFeedback}
      >
        <Form>
          <Box display="flex" flexDirection="column" maxWidth="600px">
            <Label field="name" label="Your name*" className="field-spacing" />
            <FormInput
              name="name"
              type="text"
              autoComplete="off"
              className="input-text"
            />
            <ErrorMessage
              name="name"
              className="input-error"
              component="span"
            />

            <Label
              field="email"
              label="Your e-mail address*"
              className="field-spacing"
            />
            <FormInput
              name="email"
              type="text"
              autoComplete="off"
              className="input-text"
            />
            <ErrorMessage
              name="email"
              className="input-error"
              component="span"
            />

            <Label
              field="message"
              label="Your message to us*"
              className="field-spacing"
            />
            <FormInput
              name="message"
              as="textarea"
              autoComplete="off"
              className="input-text message"
            />
            <ErrorMessage
              name="message"
              className="input-error"
              component="span"
            />
            <span className="form-fields-sub-text">
              If you are correcting what you believe to be an error in our
              registry, please be as precise as possible and provide us with as
              much information as possible about the entry you are correcting.
            </span>

            <span>
              <button
                type="submit"
                className="button field-spacing"
                disabled={submitting}
              >
                Submit
              </button>
            </span>
            <Loading loading={submitting} />
          </Box>
        </Form>
      </Formik>
    </div>
  );
};

export namespace Paths {
  export const oldVinesInfo = "/old-vines-info";
  export const about = "/about";
  export const contest = "/contest";
  export const submit = "/submit";
  export const feedback = "/feedback";
  export const privacy = "/privacy";
  export const faq = "/faq";
  export const thankYou = "/thank-you";
  export const vineyards = "/vineyards";
  export const vineyard = "/vineyard";
  export const login = "/login";
  export const admin = "/admin";
  export const synonym = "/synonym";
}

import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

export interface LinkProps extends LayoutProps, SpaceProps {
  to: string;
  className?: string;
  children?: React.ReactNode;
}

const StyledLink = styled(Link)`
  ${layout}
  ${space}
  text-decoration: none;
  color: inherit;
`;

export const NavigationLink = ({ children, ...props }: LinkProps) => (
  <StyledLink {...props}>{children}</StyledLink>
);

import { Box } from "../../components/styled-system/Box";
import { Divider } from "../../components/layout/Divider";
import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  downloadDatabase,
  uploadDatabase,
  downloadSynonyms,
  uploadSynonyms,
  downloadSearchHistory
} from "../../api/old-vine-registry-api";

const UploadCompleted = (props: { uploadCompleted: boolean }) => (
    <>
      {props.uploadCompleted && (
          <span style={{paddingLeft:'20px', fontSize:"medium"}} >
            {props.uploadCompleted && "Upload Completed"}
          </span>
      )}
    </>
);

const Loading = (props: { loading: boolean }) => (
  <>
    {props.loading && (
      <Box display="inline-block" height="25px" ml="20px">
        {props.loading && <Spinner animation="border" role="status" />}
      </Box>
    )}
  </>
);

export const AdminPage = () => {
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [csvFile, setCsvFile] = useState<File>();
  const [error, setError] = useState("");
  const [vineyardValue, setVineyardValue] = useState('');
  const [synonymValue, setSynonymValue] = useState('');

  const validate = (file?: File) => {
    if (!file) {
      setError("TSV file required");
      return false;
    }
    if (!file.name.match(/.*\.[ct|CT][s|S][v|V]/)) {
      setError("File must be of type CSV or TSV");
      return false;
    }
    setError("");
    return true;
  };

  const handleVineyardsChange = (e:any) => {
    setVineyardValue(e.target.value);

    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
      validate(e.target.files[0]);
    }
  };

  const handleSynonymChange = (e:any) => {
    setSynonymValue(e.target.value);

    if (e.target.files && e.target.files.length > 0) {
      setCsvFile(e.target.files[0]);
      validate(e.target.files[0]);
    }
  };

  const downloadVineyardsTsv = () => {
    setDownloading(true);
    downloadDatabase().finally(() =>
      setTimeout(() => setDownloading(false), 2000)
    );
  };

  const downloadSynonymTsv = () => {
    setDownloading(true);
    downloadSynonyms().finally(() =>
      setTimeout(() => setDownloading(false), 2000)
    );
  };

  const downloadSearchHistoryTsv = () => {
    setDownloading(true);
    downloadSearchHistory().finally(() =>
      setTimeout(() => setDownloading(false), 2000)
    );
  };

  const uploadVineyardTsv = () => {
    if (csvFile && validate(csvFile)) {
      setUploading(true);
      setUploadCompleted(false);
      uploadDatabase(csvFile).then(() => {
        setUploading(false);
        setUploadCompleted(true);
        setVineyardValue('');
      });
    }
  };

  const uploadSynonymTsv = () => {
    if (csvFile && validate(csvFile)) {
      setUploading(true);
      setUploadCompleted(false);
      uploadSynonyms(csvFile).then(() => {
        setUploading(false);
        setUploadCompleted(true);
        setSynonymValue('');
      });
    }
  };

  return (
    <Box className="submit-layout" maxWidth="1000px">
      <div className="title-large">Administration</div>
      <Box mt="2.5rem">
        <span className="title-small">Database update:</span>
        <br />
        <span className="description-text-center">
          To make updates to the vineyard database, please upload a TSV file here.
        </span>
        <br />
        {error && <span className="input-error">{error}</span>}
        <br />
        <Form.Control
          type="file"
          className="input-text"
          value={vineyardValue}
          onChange={handleVineyardsChange}
        />
        <span className="warning-text">
          WARNING: This is a destructive process - it will overwrite all existing data.
        </span>
        <br/>
        <button
          className="button u-mt-medium"
          onClick={uploadVineyardTsv}
          disabled={uploading}
        >
          Upload Vineyards
        </button>
        <br />
        <br />
        <span className="description-text-center">
          To make updates to the synonym table, please upload a TSV file here.
        </span>
        <Form.Control
          type="file"
          className="input-text"
          value={synonymValue}
          onChange={handleSynonymChange}
        />
        <span className="warning-text">
          WARNING: This is a destructive process - it will overwrite all existing data.
        </span>
        <br/>
        <button
          style={{marginLeft: '5px'}}
          className="button u-mt-medium"
          onClick={uploadSynonymTsv}
          disabled={uploading}
        >
          Upload Synonyms
        </button>
        <Loading loading={uploading} />
        <UploadCompleted uploadCompleted={uploadCompleted} />
        <br/>
        
        <br/><br/>
        <div className="title-large">Downloads</div>
        <span className="description-text-center u-mr-medium">
          Download the current data to .TSV file(s):
        </span><br />
        <button className="button" onClick={downloadVineyardsTsv} disabled={downloading}>
          Download Vineyard Info
        </button><br /><br />
        <button className="button" onClick={downloadSynonymTsv} disabled={downloading}>
          Download Synonyms
        </button><br /><br />
        <button className="button" onClick={downloadSearchHistoryTsv} disabled={downloading}>
          Download Search History
        </button><br />
        <Loading loading={downloading} />
        <Divider />
      </Box>

      {/*<Box height="150px" />*/}
    </Box>
  );
};

import React, { createRef, useEffect } from "react";
import "./App.scss";
import styled from "styled-components";
import { AppHeader } from "./components/app-header/AppHeader";
import { AppFooter } from "./components/app-footer/AppFooter";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { MainPage } from "./pages/main-page/MainPage";
import { Paths } from "./paths";
import { AboutPage } from "./pages/about/AboutPage";
import { ContestPage } from "./pages/contest/Contest";
import { PrivacyPage } from "./pages/privacy/PrivacyPage";
import { FaqPage } from "./pages/faq/FaqPage";
import { OldVinesInfoPage } from "./pages/old-vines-info/OldVinesInfoPage";
import { SubmitPage } from "./pages/submit/SubmitPage";
import { FeedbackPage } from "./pages/feedback/FeedbackPage";
import ReactBreakpoints from "react-breakpoints";
import "bootstrap/dist/css/bootstrap.min.css";
import { VineyardsTable } from "./pages/vineyards-table/VineyardsTable";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Vineyard } from "./pages/vineyard/Vineyard";
import { ThankYouPage } from "./pages/thank-you/ThankYouPage";
import { AdminPage } from "./pages/admin/AdminPage";
import { Guarded } from "./components/guard/Guarded";
import { authGuard } from "./guards/authGuard";
import { LoginPage } from "./pages/login/LoginPage";
import { Box } from "./components/styled-system/Box";

const breakpoints = {
  mobile: 380,
  desktop: 680,
};

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = (props: { children: React.ReactNode }) => {
  const containerElmRef = createRef<HTMLElement>();
  const { pathname } = useLocation();
  //Scroll to top when the path changes
  useEffect(() => {
    containerElmRef.current?.scrollTo({ top: 0 });
  }, [pathname, containerElmRef]);

  return (
    <Box ref={containerElmRef} flex="1" overflow="auto">
      {props.children}
    </Box>
  );
};

const queryClient = new QueryClient();

function App() {
  return (
    <ReactBreakpoints breakpoints={breakpoints}>
      <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <AppLayout>
                    <AppHeader />
                    <Content>
                      <Outlet />
                      <AppFooter />
                    </Content>
                  </AppLayout>
                }
              >
                <Route index element={<MainPage />} />
                <Route path={Paths.about} element={<AboutPage />} />
                <Route path={Paths.contest} element={<ContestPage />} />
                <Route path={Paths.oldVinesInfo} element={<OldVinesInfoPage />} />
                <Route path={Paths.submit} element={<SubmitPage />} />
                <Route path={Paths.feedback} element={<FeedbackPage />} />
                <Route path={Paths.privacy} element={<PrivacyPage />} />
                <Route path={Paths.faq} element={<FaqPage />} />
                <Route path={Paths.thankYou} element={<ThankYouPage />} />
                <Route path={Paths.vineyards} element={<VineyardsTable />} />
                <Route path={Paths.vineyard} element={<Vineyard />} />
                <Route
                  path={Paths.admin}
                  element={
                    <Guarded guards={[authGuard]}>
                      <AdminPage />
                    </Guarded>
                  }
                />
              </Route>
              <Route path={Paths.login} element={<LoginPage />} />
            </Routes>
          </BrowserRouter>
      </QueryClientProvider>
    </ReactBreakpoints>
  );
}

export default App;
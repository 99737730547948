import { useState } from 'react';
import './Accordian.scss'; 

export const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? 'active' : '';
    const header = index === activeIndex ? 'accordion-header-active' : 'accordion-header';

    return (
      <div key={item.title} className={`accordion-item ${active}`}>
        <button className={`description-text ${header}`} onClick={() => onTitleClick(index)}>
          {item.title}
        </button>
        <div className={`description-text accordion-body ${active}`}>
          <p>{item.content}</p>
        </div>
      </div>
    );
  });

  return <div className="accordion">{renderedItems}</div>;
};
import "./AppHeader.scss";
import styled from "styled-components";
import Vine from "../../images/vine.svg";
import { Spacer } from "../layout/Spacer";
import { Paths } from "../../paths";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Media } from "react-breakpoints";
import { GiHamburgerMenu } from "react-icons/gi";
import { Box } from "../styled-system/Box";
import { useState } from "react";

const HeaderLayout = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 88px;
  padding: 0 104px;
`;

const VineImage = styled.img`
  margin-top: 16px;
  cursor: pointer;
`;

const DesktopLayout = ({ navigate }: { navigate: NavigateFunction }) => {
  return (
    <HeaderLayout className="heading-font">
      <VineImage src={Vine} onClick={() => navigate("/")} />
      <Spacer />
      <span className="menu-item" onClick={() => navigate("/")}>
        Search
      </span>
      <a
        className="menu-heading"
        href={`${Paths.submit}`}
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Added for security
        onClick={(e) => {
          e.preventDefault(); // Prevent the default link behavior
          navigate(`${Paths.submit}`);
        }}>
        <span className="menu-item u-ml-large">
          Submit Vineyard
        </span>
      </a>
      <a
        className="menu-heading"
        href={`${Paths.oldVinesInfo}`}
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Added for security
        onClick={(e) => {
          e.preventDefault(); // Prevent the default link behavior
          navigate(`${Paths.oldVinesInfo}`);
        }}>
        <span className="menu-item u-ml-large">
          Why Old Vines?
        </span>
      </a>
      
      <a
        className="menu-heading"
        href={`${Paths.about}`}
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Added for security
        onClick={(e) => {
          e.preventDefault(); // Prevent the default link behavior
          navigate(`${Paths.about}`);
        }}>
        <span className="menu-item u-ml-large">
        About
        </span>
      </a>

      <a
        className="menu-heading"
        href={`${Paths.faq}`}
        target="_blank" // Open in a new tab
        rel="noopener noreferrer" // Added for security
        onClick={(e) => {
          e.preventDefault(); // Prevent the default link behavior
          navigate(`${Paths.faq}`);
        }}>
        <span className="menu-item u-ml-large">
        FAQ
        </span>
      </a>
      
      <span className="menu-item u-ml-large">
        <a className="menu-heading"
          href="https://www.oldvines.org/checkout/donate?donatePageId=6462a1da9d9f251340b8f1b2"
          target="_blank"
          rel="noreferrer">
            Donate</a>
      </span>
    </HeaderLayout>
  );
};

const MobileHeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  padding: 0 24px;
`;

const VineImageMobile = styled.img`
  margin-top: 17px;
  cursor: pointer;
  width: 48px;
  height: 48px;
`;

const MobileLayout = ({ navigate }: { navigate: NavigateFunction }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    setMenuVisible((visible) => !visible);
  };

  const nav = (to: string) => {
    toggleMenu();
    navigate(to);
  };

  return (
    <header>
      <MobileHeaderLayout className="heading-font">
        <VineImageMobile src={Vine} onClick={() => navigate("/")} />
        <Spacer />
        <GiHamburgerMenu
          size="24px"
          onClick={toggleMenu}
          className="menu-item"
        />
      </MobileHeaderLayout>
      <div className={`menu ${menuVisible ? "menu-expanded" : ""}`}>
        <Box display="flex" flexDirection="column">
          <span className="heading-font menu-item" onClick={() => nav("/")}>
            Search
          </span>
          <span
            className="heading-font menu-item u-mt-medium"
            onClick={() => nav(Paths.submit)}
          >
            Submit Vineyard?
          </span>
          <span
            className="heading-font menu-item u-mt-medium"
            onClick={() => nav(Paths.oldVinesInfo)}
          >
            Why Old Vines?
          </span>
          <span
            className="heading-font menu-item u-mt-medium"
            onClick={() => nav(Paths.about)}
          >
            About
          </span>
          <span
            className="heading-font menu-item u-mt-medium"
            onClick={() => nav(Paths.faq)}
          >
            FAQ
          </span>
          <span className="heading-font menu-item u-mt-medium">
          <a className="menu-heading"
              href="https://www.oldvines.org/checkout/donate?donatePageId=6462a1da9d9f251340b8f1b2"
              target="_blank"
              rel="noreferrer">
            Donate</a>
          </span>
        </Box>
      </div>
    </header>
  );
};

export const AppHeader = () => {
  const navigate = useNavigate();

  return (
    <Media>
      {({ currentBreakpoint }) => {
        return currentBreakpoint === "mobile" ? (
          <MobileLayout navigate={navigate} />
        ) : (
          <DesktopLayout navigate={navigate} />
        );
      }}
    </Media>
  );
};
